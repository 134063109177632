import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const StyledSvg = styled.svg`
  cursor: ${({ onClick }) => (!!onClick ? 'pointer' : 'default')};
`;

enum IconSize {
  SM = 'SM',
  MD = 'MD',
  LG = 'LG',
}

interface IconProps {
  className?: string;
  fillColor?: string;
  strokeColor?: string;
  name: string;
  onClick?: Function;
  size?: IconSize;
  width?: string;
  height?: string;
  svg: {
    viewBox: string;
    path: string;
  };
}

const Icon = ({
  className,
  fillColor,
  strokeColor,
  name,
  onClick,
  size = IconSize.MD,
  width,
  height,
  svg,
}: IconProps) => {
  const themeContext = useContext(ThemeContext);
  return (
    <StyledSvg
      name={name}
      onClick={onClick ? () => onClick() : () => {}}
      className={className}
      viewBox={svg.viewBox}
      color={strokeColor || themeContext.colours.black}
      height={height || themeContext.sizes.icon[size]}
      width={width || themeContext.sizes.icon[size]}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={fillColor || 'transparent'}
        stroke={strokeColor || themeContext.colours.black}
        d={svg.path}
      />
    </StyledSvg>
  );
};

export { IconSize };
export default Icon;
