import { ReactNode } from 'react';
import { ImageDataLike } from 'gatsby-plugin-image';

enum ColourLevel {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  BLACK = 'BLACK',
  WHITE = 'WHITE',
}

type Session = {
  name: String;
  teaser: ReactNode | string;
  image?: ImageDataLike;
  slug?: string;
};

type Speaker = {
  id: string;
  name: string;
  tagline?: string;
  image: ImageDataLike;
  websiteUri?: string;
  slug: string;
  content?: {
    childMarkdownRemark: {
      html: string;
    };
  };
  category?: string;
  bonusSession: boolean;
};

type Address = {
  line1: string;
  line2?: string;
  country: string;
  region: string;
  city: string;
  zip: string;
};

type User = {
  email?: string;
  uid?: string;
  address?: Address;
  admin?: boolean;
};

export { ColourLevel, Session, Speaker, User, Address };
